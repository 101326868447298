import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl,  config, ImagesUrl, Currency, FormatNumber} from '../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Cookies from 'js-cookie'
import { ValidateIsOpen } from '../component/globalFunction'
import Header from '../component/header_no_login'
import { encrypt } from '../component/authentication'

//var CryptoJS = require('crypto-js')

 const Registration =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({});
	


    const [applicant, setApplicant] = useState({
        code:Math.random().toString(36).substring(2,9),
		email:'',
		password:'',
		gender:'Male',
        fullname:'',
        confirmPassword:'',
        reffered:[]
    })

   


var today = new Date().toISOString().slice(0,10);



    const handleChange = event =>{		
        let {name, value} = event.target;	
       setApplicant({...applicant, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(applicant));
        setIsSubmitting(true);
    }




    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!values.fullname){
            errors.fullname ='Please enter fullname';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

 
        if(values.password.length <6){
              errors.password ='Please enter minimum of 6 characters';
          } 

        if(values.password!==values.confirmPassword){
            errors.password ='Password does not match.';
        }
        
        return errors;
        }

        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])
	
	

 function submit(){  
                setNotice({...notice, 
                  isLoading: true})     
              
            let formData = applicant;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
            fd.append("reffered_by", '')
            
            
            let url = ServerUrl+'/save_controller.php?tablename=tbl_agent_application';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){
                
              window.scrollTo(0,0)
              setErrors({...errors, successMessage: response.data.message})
                  // window.open('/login', '_self')

                  setTimeout(()=>{

                    //1000 * 60 * SESSION_IDEL_MINUTES
        var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
        var inOneMinutes = Date.now() + 418 * 60 * 1000;
        var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
                
                
        Cookies.set("_tmapp", inOneMinutes, { expires: inOneHours});
				Cookies.set("_btmapp", inSixtyMinutes, { expires: inOneHours });

			Cookies.set('appstnm', encrypt(applicant.fullname), {expires: inOneHours })
			Cookies.set('apptk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			Cookies.set('appid', encrypt(response.data.applicationID),  {expires: inOneHours }) 
  
           window.open('/dashboard', '_self')

                        
        
                        },3000)


                      }  else{
                        //Alerts('Error!', 'danger', response.data)
                        window.scrollTo(0,0)
                  setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                          }  
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{

                    setApplicant({
                         code:Math.random().toString(36).substring(2,9),
                        email:'',
                        password:'',
                        gender:'Male',
                        fullname:'',
                        confirmPassword:'',
                        reffered:[]
                    })
                    setNotice({...notice, 
                      isLoading: false
                    })
                    setIsSubmitting(false);
                  }) 
              }


              const ValidateExistence=()=>{
                if(applicant.email!==''){
                       
                    const fd = new FormData();
                    fd.append("data", applicant.email)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_agent&column=email_address';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, email:applicant.email + ' already register, please login to continue'})
                            setApplicant({...applicant, email:''});
                            return '';
                        }
                  })
                }
            } 


useEffect(()=>{  
   //fetchApplication()
    //handleSession()
},[]);


        return (<>
        
          <Preloader />

<div className="pcoded-overlay-box"></div>
        
          <section style={{background: `url(${ImagesUrl}/background/b.png) `, backgroundRepeat:'no-repeat', backgroundSize:'cover'}} 
			className="login-block">


		  <div className="container">
			  <div className="row">
				  <div className="col-sm-12">
					  
		  

          </div>
        </div>


		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
          <div className="row">
				
      
        <div className="col-md-6">

        <div style={{height:'60px'}}>
        <div className="alert alert-success mt-3">
        <h3 className="text-green">NEW USER FORM </h3>
</div>

            
</div>

	
    

                <div className=" card bg-info">
		<div className="card-block">
		
			<div className="card-body">

            <div className='text-white'><h4>BEFORE YOU START?</h4>
			<h5>Before you start the application process, there are a few things you should know.</h5>
            <br/>

           
<h5 className='text-align'>1. You must create an account first, after which you will be able to apply for any of the available application.</h5>

<br/>
			<h5 className='text-align'>2. You must give a valid email address for the account to be associated with. This email address will be used to communicate with you, so it should be one that you have access to.</h5>
<br/>
			<h5 className='text-align'>3. You must create a password that is at least 6 characters long and contains one letter, one number, or one symbol, and it must be unique.</h5>
            <br/>
			<h5 className='text-align'>4. We'll send you a verification link and send you a confirmation message when you create an account. To start your account application, look for the account activation email in your inbox or spam folder.</h5>

            
			</div>

			</div>
			
		
		</div>
        </div>    

                            </div>
                            <div className="col-md-6">
                       
                
                            {errors.successMessage?
    <div className="alert alert-success mt-3">
	<p className="icontext"><i className="icon text-success fa fa-check"></i>  {errors.successMessage}</p>
</div>:''}

{errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}

	<form className="md-float-material form-material" role={'presentation'} >
	<div className=" card">
		<div className="card-block">
			

<div>
<div className="text-center">
		  <img src={ImagesUrl+'/about/logo.png'} style={{ maxWidth:'150px', maxHeight:'150px' }}  alt="logo.png" />
		  </div>
<div className="row m-b-20">
				<div className="col-md-12">
					<h3 className="text-center">Registration </h3>
				</div>
			</div>

			<div className="form-group form-primary">
            <label className='text-bold' > Fullname </label>
				<input type="text"  name="fullname" role={'presentation'} autoComplete='off' value={applicant.fullname} placeholder="Your fullname" onChange={handleChange} className={errors.fullname  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
			</div>

            <span className=" form-bar error">{ errors.fullname}</span>

			<div className="form-group form-primary">
            <label className='text-bold'> Email Address </label>
			<input type="email" id="email" name="email" onBlur={ValidateExistence} role={'presentation'} autoComplete='off'  value={applicant.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
			
			</div><span className=" form-bar error">{errors.email }</span>

            <div className="form-group form-primary">
				
						<label className='text-bold'> Gender </label>
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={applicant.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={applicant.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

					</div>


            <div className="row">
                    <div className="col-sm-6">
                        <div className="form-group form-primary">
                            <input type="password" id="password" name="password" role={'presentation'} autoComplete='off'  value={applicant.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
                            
                        </div><span className=" form-bar error">{errors.password }</span>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group form-primary">
                            <input type="password" name="confirmPassword" role={'presentation'} autoComplete='off' placeholder="Confirm Password"  value={applicant.confirmPassword}  onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
                        </div>
                            <span className=" form-bar error">{errors.password }</span>
                    </div>
                </div><hr/>

                <div className="row ">
               
					<div className="text-inverse text-left col-md-6"><a href="/applicant_login" className='btn btn-inverse'><b className="f-w-600">Back to Login</b></a></div>
			
				
                        <div className="col-md-6">
                       
                        {notice.isLoading?
          
          <button disabled className="btn btn-secondary text-right " type="button"><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Please wait...</button>:
					<button type="button" onClick={handleSubmit} className="btn btn-success active f-w-600">CREATE ACCOUNT</button>}
						</div>
					
					</div>

            </div>

			
		</div>
                            </div>
                        </form>
          
        </div>  
    </div>
       
    </div>
    </section> 
</> 

 );
        
}

export default React.memo(Registration)  