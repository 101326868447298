import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {ServerUrl,  Token,  config,  ImagesUrl,  agentCode, Currency, FormatNumber} from '../component/include'

import Select from 'react-select'
import { Alerts } from '../component/notify'

const Dashboard =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


const [applicationType, setApplicationType]= useState([])
const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({});

var today = new Date().toISOString().slice(0,10);

const [applicant, setApplicant] = useState({
    code:Math.random().toString(36).substring(2,9),
    email:'',
    password:'',
    gender:'Male',
    fullname:'',
    dateOfBirth:'',
    applicationType:'',
    confirmPassword:'',
    startYear:'',
    schoolAttended:JSON.stringify([
        {code:1, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
        {code:2, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
    ])
})

const handleReset =()=>{
    setApplicant({
        code:Math.random().toString(36).substring(2,9),
        email:'',
        password:'',
        gender:'Male',
        fullname:'',
        dateOfBirth:'',
        applicationType:'',
        confirmPassword:'',
        startYear:'',
        schoolAttended:JSON.stringify([
            {code:1, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
            {code:2, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
        ])
})
setApplicant({...applicant, applicationType:''})
}




const handleChange = event =>{		
    let {name, value} = event.target;	
   setApplicant({...applicant, [name] : value });
   setErrors({...errors, [name]:''});
}

const getAppName =(code)=>{
    const ans = applicationType&&applicationType.filter(item=>item.ID===code)
    return ans.length!==0?ans[0].applicationName:''
}

    const fetchApplication =()=>{

    var sql ="select t.applicationName, a.startDate, a.feeCharge, a.endDate, a.created_at, a.id, a.code, s.startYear from tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.id = a.schoolSession and t.code = a.applicationType and date_format(a.startDate, '%Y-%m-%d') <=  '"+today+"' and date_format(a.endDate, '%Y-%m-%d') >=  '"+today+"'  order by a.id DESC "
            
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
                setApplicationType(result.data)
            }
            
        })
        .catch((error)=>{
            //Alerts('Error!', 'danger', error.message)
        })
    }



    const handleChangeType = (code, startYear) =>{		
        setApplicant({...applicant, applicationType : code, startYear:startYear });
    window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
     }




const handleSubmit = event =>{
    event.preventDefault();
     
setErrors(ValidateInput(applicant));
    setIsSubmitting(true);
   // window.scrollTo({top:0, left:0, behavior:'smooth'})
}

const ValidateInput =(values)=>{
    let errors = {};   
    let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    
    if(!values.fullname){
        errors.fullname ='Please enter fullname';
    }
    if(!email){
        errors.email ='Please enter valid email address';
    }

    if(!values.dateOfBirth){
        errors.dateOfBirth ='Please enter date of birth';
    }
    return errors;
    }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        submit()
        }
        },[errors])



function submit(){  
    setNotice({...notice, 
        isLoading: true})     
    
  let formData = applicant;
  const fd = new FormData();
  fd.append('agent', agentCode);
  fd.append('jwt', Token);
  for(let k in formData){
  fd.append(k, formData[k])
  }
  
  let url = ServerUrl+'/save_controller.php?tablename=start_request';
  axios.post(url, fd, config)
  .then(response =>{
  if(response.data.type === 'success'){
    Alerts('Great', 'success', response.data.message)
               window.open('/overview/'+response.data.applicationID+'/'+applicant.applicationType, '_self')
                  } else{
                //Alerts('Error!', 'danger', response.data.message)
                window.scrollTo(0,0)
          setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                  }   
              })
              .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{

                setNotice({...notice, 
                  isLoading: false
                })
                setIsSubmitting(false);
              })  
          }


         
useEffect(()=>{
   fetchApplication() 
},[]);

return (  <Template  menu='dashboard'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                                    <div className="page-body m-t-50">
                          

                                        
                                                    {errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}
       

<div className="row pull-center">


<div className="col-md-10">
<div className=" m-b-20">
				
                <a href='/application' className="btn btn-primary btn-block ">CONTINUE APPLICATIONS</a>
				</div>
			</div>
            <div className="col-md-10">
<h5 className='text-center text-bold'>OR</h5>
</div>
<div className="col-md-10">
<div className=" m-b-20 m-t-20">
				
					<h3 className="text-center text-inverse">SELECT NEW APPLICATION TYPE</h3>
				</div>
			</div>
            </div>


<div className="row pull-center">

{applicationType.length!==0?applicationType.map((data, id)=>
            <div  className=" col-md-10 mb-2 " key={id} onClick={()=>handleChangeType(data.code, data.startYear)}  >

<div  style={{cursor:'pointer'}} className={applicant.applicationType===data.code?'card user-card  bg-success':'card user-card  bg-warning'}  >
        <div className="card-body ">
            
        <h6>{data.applicationName.toUpperCase()}</h6>
            <h4>{Currency+FormatNumber(data.feeCharge)}</h4>
      
    </div></div>
</div>
):<h4 className='col-md-10 text-red'></h4>}

</div>



           



{applicant.applicationType!==''?

<div className="row pull-center" id="newApplication">

<div className="col-md-8 ">

<div className=" card ">
		<div className="card-block ">
			<div className="card-body ">


            <div className="text-center">
		  <img src={ImagesUrl+'/linelogo.png'}  alt="logo.png" />
		  </div>
					<h3 className="">{getAppName(applicant.applicationType).toUpperCase()} </h3>
				
			

			<div className="form-group form-primary">
            <label className='text-bold'>Child's Fullname: </label>
				<input type="text"  name="fullname" role={'presentation'} autoComplete='off' value={applicant.fullname} placeholder="Your fullname" onChange={handleChange} className={errors.fullname  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
			</div>

            <span className=" form-bar error">{ errors.fullname}</span> 

			<div className="form-group form-primary">
            <label className='text-bold'> Email Address: </label>
			<input type="email" id="email" name="email" role={'presentation'} autoComplete='off'  value={applicant.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 


			</div>
			<span className=" form-bar error">{errors.email }</span>
            <div className="form-group form-primary">
            <label className='text-bold' >Child's Date of Birth: </label>
				<input type="date"  name="dateOfBirth" role={'presentation'} autoComplete='off' value={applicant.dateOfBirth}  onChange={handleChange} className={errors.dateOfBirth  ? 'form-control formerror form-control' : 'form-control form-control-lg'} /> 
			</div>

            <span className=" form-bar error">{ errors.dateOfBirth}</span>
            <div className="form-group form-primary">
				
						<label className='text-bold'> Child's Gender </label>
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={applicant.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={applicant.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

					</div>

                <div className="row mt-5">
					<div className="col-md-6 " >
                    
						<button type="button"  className="btn btn-inverse text-bold " onClick={handleReset}> CANCEL</button>
                        </div>
                        <div className="col-md-6 ">
                       
                        {notice.isLoading?
          
          <button disabled className="btn btn-secondary" type="button"><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Please wait...</button>:
					<button type="button" onClick={handleSubmit} className="btn btn-success btn-block active text-bold">START APPLICATION</button>}
						</div>
					
					</div> 
                    
                    
                     </div>
                    </div>
          
        
            </div>
            </div>
            </div>:[]}
                    <br/>
                                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
}

export default React.memo(Dashboard) 