import React, {useState, useEffect} from 'react';
import Preloader from '../component/preloader'
import Footer from '../component/footer'
import Header from '../component/header'
import Sidebar from './sidebar';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import { API_URL, config, configApi } from './include';
const Template = (props) =>
{
   	
    const dispatch = useDispatch()
    const fetchCountry = async ()=>{
        let url = API_URL+'/geo/country/all'
            const {data} = await axios.get(url, configApi)
            dispatch({
                type:'SET_RECORDS',
                name:'countries',
                data:data.data
            })
        }
       
      


         useEffect(()=>{
             fetchCountry();
             },[]);

    return (<React.Fragment>
        {/* <!-- Pre-loader start --> */}
        <Preloader />
        {/* <!-- Pre-loader end --> */}


        <div id="pcoded" className="pcoded">

    <div className="pcoded-container">


       {/* Header Component Here */}

       <Header />
{/* The  header End here */}


 <div className="pcoded-main-container">
 <Sidebar menu={props.menu} /> 



                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                          

            {/* <!-- Main-body start --> */}
            {props.children}

        
            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div> 
           {/* The Main Body close here */}            
           </div></div>

            


</div>
            </div> 
        
        
        
    </React.Fragment>)
}
export default React.memo(Template)